import React from 'react'
import { Link } from 'gatsby'

import './footer.css'

const Footer = () => (
  <div>
    <div className="footer">
      <div className="submenu">
        <ul className="inline-list">
          <li>
            <Link to="/">About</Link>
          </li>
        </ul>
      </div>
      <div className="copyright">
        <p>This website doesn't track you and it doesn't use cookies!<br/>
        Copyright &copy; 2023 Ewerton Carlos Assis</p>
      </div>
    </div>
  </div>
)

export default Footer
